// store images
import epicStore from '../imgs/stores/epic.png';
import steamStore from '../imgs/stores/steam.png';
import bigFishStore from '../imgs/stores/bigfish.png';
import wildTangentStore from '../imgs/stores/wildtangent.png';
import itchStore from '../imgs/stores/itch.png';
import comingSoon from '../imgs/stores/comingsoon.png';

// Bug Hunter images
import bughunterThumbnail from '../imgs/gamethumbs/bughuntercover.jpg';
import bughunterScreen1 from '../imgs/games/bughunter/screenshots/bughunter1.jpg';
import bughunterScreen2 from '../imgs/games/bughunter/screenshots/bughunter2.jpg';
import bughunterScreen3 from '../imgs/games/bughunter/screenshots/bughunter3.jpg';

// HC3 images
import hc3Thumbnail from '../imgs/gamethumbs/hc3cover.jpg';
import hc3Background from '../imgs/games/hc3/hc3bg.jpg';
import hc3Screen2 from '../imgs/games/hc3/screenshots/holidaycheer3-02.jpg';
import hc3Screen6 from '../imgs/games/hc3/screenshots/holidaycheer3-06.jpg';
import hc3Screen12 from '../imgs/games/hc3/screenshots/holidaycheer3-12.jpg';
import hc3Screen13 from '../imgs/games/hc3/screenshots/holidaycheer3-13.jpg';
import hc3Screen14 from '../imgs/games/hc3/screenshots/holidaycheer3-14.jpg';
import hc3Screen16 from '../imgs/games/hc3/screenshots/holidaycheer3-16.jpg';
import hc3Screen2_thm from '../imgs/games/hc3/screenshots/thumbs/holidaycheer3-02_thm.jpg';
import hc3Screen6_thm from '../imgs/games/hc3/screenshots/thumbs/holidaycheer3-06_thm.jpg';
import hc3Screen12_thm from '../imgs/games/hc3/screenshots/thumbs/holidaycheer3-12_thm.jpg';
import hc3Screen13_thm from '../imgs/games/hc3/screenshots/thumbs/holidaycheer3-13_thm.jpg';
import hc3Screen14_thm from '../imgs/games/hc3/screenshots/thumbs/holidaycheer3-14_thm.jpg';
import hc3Screen16_thm from '../imgs/games/hc3/screenshots/thumbs/holidaycheer3-16_thm.jpg';

// MAGA images
import magaThumbnail from '../imgs/gamethumbs/magacover.jpg';
import magaBackground from '../imgs/games/maga/screenshots/maga4.png';
import magaScreen1 from '../imgs/games/maga/screenshots/maga1.png';
import magaScreen2 from '../imgs/games/maga/screenshots/maga2.png';
import magaScreen3 from '../imgs/games/maga/screenshots/maga3.png';
import magaScreen4 from '../imgs/games/maga/screenshots/maga4.png';
import magaScreen5 from '../imgs/games/maga/screenshots/maga5.png';

export const gamedata = [
  {
    gameName: 'bughunter',
    title: 'Bug Hunter',
    description:
      'Action rogue-lite mobile game where you destroy waves of bugs and bosses.',
    thumbNail: bughunterThumbnail,
    whereToBuy: [
      {
        name: 'Coming Soon',
        icon: comingSoon,
        url: 'https://www.doommetalgames.com/',
      },
    ],
    backgroundImage: hc3Background,
    screenshots: [bughunterScreen1, bughunterScreen2, bughunterScreen3],
    thumbnails: [hc3Screen6_thm, hc3Screen12_thm, hc3Screen13_thm],
  },
  {
    gameName: 'hc3',
    title: 'Holiday Cheer 3',
    description: '100 levels of Christmas themed Match 3 Puzzle gameplay fun!',
    thumbNail: hc3Thumbnail,
    whereToBuy: [
      {
        name: 'Steam',
        icon: steamStore,
        url: 'https://store.steampowered.com/app/1765730/Holiday_Cheer_3/',
      },
      {
        name: 'Big Fish Games',
        icon: bigFishStore,
        url: 'https://www.bigfishgames.com/us/en/games/15505/holiday-cheer-iii/?lang=en',
      },
    ],
    backgroundImage: hc3Background,
    screenshots: [
      hc3Screen6,
      hc3Screen12,
      hc3Screen13,
      hc3Screen14,
      hc3Screen16,
      hc3Screen2,
    ],
    thumbnails: [
      hc3Screen6_thm,
      hc3Screen12_thm,
      hc3Screen13_thm,
      hc3Screen14_thm,
      hc3Screen16_thm,
      hc3Screen2_thm,
    ],
  },
  {
    gameName: 'maga',
    title: 'Make America Glow Again',
    description: 'Tongue-in-cheek missile defense arcade game.',
    thumbNail: magaThumbnail,
    whereToBuy: [
      {
        name: 'Itch.io',
        icon: itchStore,
        url: 'https://doommetalgames.itch.io/make-america-glow-again',
      },
    ],
    backgroundImage: magaBackground,
    screenshots: [
      magaScreen1,
      magaScreen2,
      magaScreen3,
      magaScreen4,
      magaScreen5,
    ],
    thumbnails: [
      magaScreen1,
      magaScreen2,
      magaScreen3,
      magaScreen4,
      magaScreen5,
    ],
  },
];
