import React from 'react';
import ImageDisplay from '../components/ImageDisplay';
import bannerImg from '../../src/imgs/wbanner1.jpg';

const ContactPage = () => (
  <div>
    <ImageDisplay imageUrl={bannerImg} justify="items-center" />

    <p className="text-2xl text-center py-8">
      Just an indie game developer, nothing too serious.
    </p>

    <div className="container mx-auto px-44 py-40">
      <div className="flex justify-center py-15">
        Due to spam I am not providing a direct email link.{' '}
      </div>

      <div className="flex justify-center py-15">
        Please take note you will need to email the user 'support' at the Doom
        Metal Games domain.{' '}
      </div>
    </div>
  </div>
);

export default ContactPage;
