import React from 'react';
import ReactPlayer from 'react-player';
import ImageDisplay from '../components/ImageDisplay';
import bannerImg from '../../src/imgs/wbanner1.jpg';

const HomePage = () => (
  <div>
    <ImageDisplay imageUrl={bannerImg} justify="items-center" />

    <p className="text-2xl  text-center">
      Just an indie game developer, nothing too serious.
    </p>

    <div>
      <div className="text-center font-bold py-8">
        Check out my latest release, Holiday Cheer, on{' '}
        <a
          href="https://www.bigfishgames.com/us/en/games/15505/holiday-cheer-iii/?pc&lang=en"
          className="text-blue-700"
        >
          Big Fish Games
        </a>{' '}
        and{' '}
        <a
          href="https://store.steampowered.com/app/1765730/Holiday_Cheer_3/"
          className="text-blue-700"
        >
          Steam
        </a>
      </div>
      <div className="flex justify-center py-15">
        <ReactPlayer url="https://youtu.be/jU23ois3uak" />
      </div>
    </div>
    <div className="py-10" />
  </div>
);

export default HomePage;
