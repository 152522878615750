import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Slide } from 'react-slideshow-image';
import ImageDisplay from '../components/ImageDisplay';
import 'react-slideshow-image/dist/styles.css';

import { gamedata } from '../data/gamedata';

function GameDetails() {
  const { gameName } = useParams();

  const index = () => {
    if (gameName === 'hc3') {
      return 0;
    }
    if (gameName === 'maga') {
      return 1;
    }
    return 0;
  };

  var bg = `bg-${gamedata[index()].gameName}`;
  console.log('background : ', bg);

  const slideshowimages = gamedata[index()].screenshots;

  const [selectedImage, setSelectedImage] = useState(
    gamedata[index()].screenshots[0]
  );

  const thumbnails = gamedata[index()].thumbnails;
  var bgimage = `bg-[url('${gamedata[index()].backgroundImage} ')]`;
  //${bg}
  //bg-[url('${ gamedata[index()].backgroundImage } ')]
  //<div className={`total total-${obj.state}`}>...</div>

  console.log('bgimage : ', bgimage);

  return (
    <div
      className={`p-10 items-center bg-cover ${bgimage} bg-center bg-no-repeat`}
    >
      <div className="p-10 bg-white bg-opacity-70">
        <div className="flex h-screen">
          <div className="w-1/2 p-10">
            <h1 className="text-4xl font-bold mt-4 py-2">
              {gamedata[index()].title}
            </h1>
            <img
              src={gamedata[index()].thumbNail}
              alt="Game box art"
              className="mb-4"
            />
            <p className="text-lg py-4">{gamedata[index()].description}</p>
            <div className="p-5  bg-gray-300 bg-opacity-40 ">
              <h3 className="flex justify-center text-2xl font-bold mb-4">
                Buy on these game sites:
              </h3>
              <div className="flex justify-center mt-4 ">
                {gamedata[index()].whereToBuy.map((store) => (
                  <a href={store.url} key={store.name}>
                    <img
                      key={store.name}
                      src={store.icon}
                      alt={`image for ${store.name} store`}
                      className="w-20 h-20 mx-2"
                    />{' '}
                  </a>
                ))}
              </div>
            </div>
          </div>
          <div className="w-1/2 p-10">
            <img
              src={selectedImage}
              alt="Selected"
              className="w-full h-95 mb-4 object-cover"
            />
            <div className="flex space-x-2 overflow-x-auto">
              {slideshowimages.map((thumbnail, index) => (
                <img
                  key={index}
                  src={thumbnail}
                  alt="Thumbnail"
                  className="w-32 h-24 object-cover cursor-pointer"
                  onClick={() => setSelectedImage(thumbnail)}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* <h3 className="flex justify-center text-2xl font-bold mb-4">
          Screenshots
        </h3>
        <div className="w-196  justify-center">
          <div className="slide-container px-8 ">
            <Slide>
              {slideshowimages.map((each, index) => (
                <img key={index} style={{ width: '75%' }} src={each} />
              ))}
            </Slide>
          </div>
        </div> */}
    </div>
  );
}

export default GameDetails;
