function Copyright() {
  return (
    <footer className="flex-1 bg-gray-800 ">
      <p className=" text-white text-center text-3xl font-bold py-5">
        © DOOM METAL GAMES {new Date().getFullYear()}
      </p>
    </footer>
  );
}

export default Copyright;

//<footer className="fixed bottom-0  bg-gray-800 text-white text-center text-3xl font-bold py-4 ">
