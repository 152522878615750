import React from 'react';
import { Slide } from 'react-slideshow-image';
import ImageDisplay from '../components/ImageDisplay';
import 'react-slideshow-image/dist/styles.css';

import { gamecards } from '../data/gamecards';
import ProductCard from '../components/ProductCard';

const GamesPage = () => (
  <div>
    <p className="text-2xl text-center py-8">
      Just an indie game developer, nothing too serious.
    </p>

    <div>
      <div>
        {gamecards.map((card, index) => (
          <ProductCard {...card} />
        ))}
      </div>
    </div>
  </div>
);

export default GamesPage;
