import React from 'react';
import { Link } from 'react-router-dom';

interface ProductCardProps {
  title: string;
  description: string;
  image: string;
  gameName: string;
}

const ProductCard: React.FC<ProductCardProps> = ({
  title,
  description,
  image,
  gameName,
}) => {
  return (
    <div className="max-w-md mx-auto bg-gray-100 rounded-xl shadow-md overflow-hidden md:max-w-2xl m-4">
      <div className="md:flex">
        <div className="md:flex-shrink-0">
          <img
            className="h-225 w-full object-cover md:w-400"
            src={image}
            alt="Game box art"
          />
        </div>
        <div className="p-8">
          <div className="uppercase tracking-wide text-lg text-indigo-500 font-semibold">
            {title}
          </div>
          <p className="mt-2 text-gray-500">{description}</p>
          <div className="mt-4">
            <Link
              to={`${gameName}`}
              className="text-indigo-600 hover:text-indigo-500"
            >
              Learn More{' '}
            </Link>

            <a href="#" className="ml-4 text-indigo-600 hover:text-indigo-500">
              Buy Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
