import React from 'react';

function PrivacyPolicy() {
  return (
    <React.Fragment>
      <main>
        <div className="container mx-auto  text-black ">
          <div className="body1 text-align-left"></div>
          <div className="text-4xl font-bold">Privacy Policy </div>
          <div className="">
            <p className="py-3">
              Your privacy and security are important to us.
            </p>

            <p className="py-3">
              DOOM METAL GAMES buids our games as a Commercial games. These
              games are provided as a SERVICE and are provided by DOOM METAL
              GAMES and are intended for use as is.
            </p>

            <p className="py-3">
              This page is used to inform visitors regarding my policies with
              the collection, use, and disclosure of Personal Information should
              you decided to use my Service.
            </p>

            <p className="py-3">
              If you choose to use my Service, then you agree to the collection
              and use of information in relation to this policy. The Information
              that I collect is used for providing and improving the Service. I
              will not use or share your information with anyone except as
              described in this Privacy Policy.
            </p>

            <p className="py-3">
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which is accessible at [Game Title]
              unless otherwise defined in this Privacy Policy.
            </p>
          </div>
          <div className="font-bold text-xl py-3">
            Information Collection and Use
          </div>
          <div className="body1">
            <p>
              For a better experience, while using our Service, I may gather
              certain non-identifiable information including but not limited to
              a anonymized Unique user identifier, Location, and Gameplay
              results. This information is collected via Unity Analytics service
              and is stored onto their servers. See their privacy policy
              <a
                href="https://unity3d.com/legal/privacy-policy"
                rel="noreferrer"
                target="_blank"
              >
                here
              </a>
              .
            </p>

            <p>
              The game does use third party services that may collect
              information used to identify you.
            </p>
            <p>
              Link to privacy policy of third party service providers used by
              the game
              <li>
                Unity{' '}
                <a
                  href="https://unity3d.com/legal/privacy-policy"
                  rel="noreferrer"
                  target="_blank"
                >
                  {' '}
                  here
                </a>
              </li>
            </p>
          </div>

          <div className="font-bold text-xl py-3">Sharing and Selling Data</div>
          <div className="body1">
            <p>
              This Service does not engage in the selling or sharing of data.
            </p>
            <p>
              When games are distributed by partners they may be subject to
              additional terms as outlined by those partners.
            </p>
          </div>

          <div className="font-bold text-xl py-3">Cookies</div>
          <div className="body1">
            <p>
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory.
            </p>
            <p>This Service does not use these “cookies” explicitly.</p>
          </div>

          <div className="font-bold text-xl py-3">Service Providers</div>
          <div>
            <p>
              I may employ third-party companies and individuals due to the
              following reasons:
            </p>
            <li>To facilitate our Service; </li>
            <li>To provide the Service on our behalf; </li>
            <li>To perform Service-related services; or </li>
            <li>To assist us in analyzing how our Service is used. </li>
            <p>
              I want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </p>
          </div>

          <div className="font-bold text-xl py-3">Security</div>
          <div>
            <p>
              I value your trust in providing us your Personal Information, thus
              we are striving to use commercially acceptable means of protecting
              it. But remember that no method of transmission over the internet,
              or method of electronic storage is 100% secure and reliable, and I
              cannot guarantee its absolute security.
            </p>
          </div>

          <div className="font-bold text-xl py-3">Links to Other Sites</div>
          <div>
            <p>
              This Service may contain links to other sites. If you click on a
              third-party link, you will be directed to that site. Note that
              these external sites are not operated by me. Therefore, I strongly
              advise you to review the Privacy Policy of these websites. I have
              no control over and assume no responsibility for the content,
              privacy policies, or practices of any third-party sites or
              services.
            </p>
          </div>

          <div className="font-bold text-xl py-3">Children’s Privacy</div>
          <div>
            <p>
              These Services do not address anyone under the age of 13. I do not
              knowingly collect personally identifiable information from
              children under 13. In the case I discover that a child under 13
              has provided me with personal information, I immediately delete
              this from our servers. If you are a parent or guardian and you are
              aware that your child has provided us with personal information,
              please contact me so that I will be able to take necessary
              actions.
            </p>
          </div>

          <div className="font-bold text-xl py-3">
            Changes to This Privacy Policy
          </div>
          <div>
            <p>
              I may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. I will
              notify you of any changes by posting the new Privacy Policy on
              this page. These changes are effective immediately after they are
              posted on this page.
            </p>
          </div>

          <div className="font-bold text-xl py-3">Contact Us</div>
          <div className="body1">
            <p>
              If you have any questions or suggestions about my Privacy Policy,
              do not hesitate to contact me at www.doommetalgames.com website on
              the Contact page.
            </p>
          </div>
        </div>
      </main>
    </React.Fragment>
  );
}

export default PrivacyPolicy;
