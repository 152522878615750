import React from 'react';
import ImageDisplay from '../components/ImageDisplay';
import bannerImg from '../../src/imgs/wbanner1.jpg';

const AboutPage = () => (
  <div>
    <ImageDisplay imageUrl={bannerImg} justify="items-center" />
    <p className="text-2xl text-center py-8">
      Just an indie game developer, nothing too serious.
    </p>
    <div className="container mx-auto px-44 text-left py-15">
      <p className="py-3">
        I am a an independent game developer since 2010. I have shipped 7 games
        on my own but I occaisonally partner with others to create small and fun
        games as well.
      </p>
      <p className="py-3">
        I started my journey in game development as a hobbist/game modder in
        1997 modifying DOOM II with custom map packs including new sounds,
        sprites, levels and graphics. After a few years I realized I had learned
        all the skills to create a video game on my own except for programming.
      </p>

      <p className="py-3">
        So, completely not understanding what was involved, I decided to teach
        myself to program as well!
      </p>

      <p className="py-3">
        Much to my surpise I really enjoy programming and eventually changed my
        career to software engineer, which is what I do for my day job now and
        continue to make small games on my spare time in the evenings and
        weekends.
      </p>
    </div>
  </div>
);

export default AboutPage;
