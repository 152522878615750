import React from 'react';
import { Link } from 'react-router-dom';

const TopMenuBar = () => (
  <div className="bg-gray-800 text-white py-4 shadow-md">
    <div className="container mx-auto flex justify-between items-center">
      <div className="text-lg font-bold">DOOM METAL GAMES</div>
      <nav>
        <ul className="flex space-x-4">
          <li className="hover:text-gray-300">
            <Link to="/">Home</Link>
          </li>
          <li className="hover:text-gray-300">
            <Link to="/about">About</Link>
          </li>
          <li className="hover:text-gray-300">
            <Link to="/games">Games</Link>
          </li>
          <li className="hover:text-gray-300">
            <Link to="/contact">Contact</Link>
          </li>
          <li className="hover:text-gray-300">
            <Link to="/privacy">Privacy</Link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
);

export default TopMenuBar;
