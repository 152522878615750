import hc3Image from '../imgs/gamethumbs/hc3cover.jpg';
import bughunterThumbnail from '../imgs/gamethumbs/bughuntercover.jpg';
import hc1Image from '../imgs/gamethumbs/hc1cover.jpg';
import magaImage from '../imgs/gamethumbs/magacover.jpg';

// store images
import epicStore from '../imgs/stores/epic.png';
import steamStore from '../imgs/stores/steam.png';
import bigFishStore from '../imgs/stores/bigfish.png';
import wildTangentStore from '../imgs/stores/wildtangent.png';
import itchStore from '../imgs/stores/itch.png';
import comingSoon from '../imgs/stores/comingsoon.png';

export const gamecards = [
  {
    key: 'bughunter',
    title: 'Bug Hunter',
    description:
      'Action rogue-lite mobile game where you destroy waves of bugs and bosses.',
    image: bughunterThumbnail,
    gameName: 'bughunter',
    whereToBuy: {
      location: {
        name: 'Coming Soon',
        icon: comingSoon,
        url: 'https://www.doommetalgames.com/',
      },
    },
  },
  {
    key: 'hc3',
    title: 'Holiday Cheer 3',
    description: '100 levels of Christmas themed Match 3 Puzzle gameplay fun!',
    image: hc3Image,
    gameName: 'hc3',
    whereToBuy: {
      location: {
        name: 'Steam',
        icon: steamStore,
        url: 'https://store.steampowered.com/app/1282210/Holiday_Cheer_3/',
      },
      location: {
        name: 'Big Fish Games',
        icon: bigFishStore,
        url: 'https://www.bigfishgames.com/us/en/games/15505/holiday-cheer-iii/?lang=en',
      },
    },
  },
  {
    key: 'maga',
    title: 'Make America Glow Again',
    description: 'Tongue-in-cheek missile defense arcade game.',
    image: magaImage,
    gameName: 'maga',
    whereToBuy: {
      location: {
        name: 'Itch.io',
        icon: itchStore,
        url: 'https://doommetalgames.itch.io/make-america-glow-again',
      },
    },
  },
];
